{
  "name": "character-sheet-app",
  "version": "4.3.1",
  "description": "Online character sheet for Tabletop Role-Playing Games",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/derikb/character-sheet-app.git"
  },
  "bugs": {
    "url": "https://github.com/derikb/character-sheet-app/issues"
  },
  "homepage": "https://github.com/derikb/character-sheet-app#readme",
  "keywords": [
    "d&d",
    "rpg",
    "ttrpg",
    "character sheet"
  ],
  "author": "Derik A Badman",
  "license": "GPL-3.0",
  "dependencies": {
    "firebase": "^9.16.0",
    "rpg-table-randomizer": "^1.3.2"
  },
  "devDependencies": {
    "commit-and-tag-version": "^11.0.0",
    "esbuild": "^0.17.5",
    "eslint": "^8.33.0",
    "eslint-config-standard": "^17.0.0",
    "eslint-plugin-import": "^2.27.0",
    "eslint-plugin-node": "^11.1.0",
    "eslint-plugin-promise": "^6.1.1",
    "normalize.css": "^8.0.1"
  },
  "scripts": {
    "test": "echo \"Error: no test specified\" && exit 1",
    "start": "esbuild --serve=8080 --servedir=./dist ./src/styles.css ./src/index.js ./src/service_worker.js --outdir=./dist --loader:.svg=dataurl --loader:.html=file --bundle",
    "build": "esbuild ./src/styles.css ./src/index.js ./src/service_worker.js --outdir=./dist --loader:.svg=dataurl --loader:.html=file --minify --sourcemap --bundle",
    "release": "commit-and-tag-version -a",
    "eslint": "eslint ./src"
  },
  "commit-and-tag-version": {
    "scripts": {
      "postbump": "npm run build && git add ./dist/service_worker.js ./dist/service_worker.js.map ./dist/index.js ./dist/index.js.map ./dist/styles.css ./dist/styles.css.map"
    },
    "bumpFiles": [
      "package.json",
      "package-lock.json"
    ]
  }
}
